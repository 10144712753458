
const appTheme = (color, mode) => ({
  palette: {
    mode,
    primary: {
      main: '#1a237e',
    },
    background: {
      default:'rgb(10 25 41)',
      paper: 'rgb(10 25 41)',
    },
    secondary: {
      main: '#283593',
    },
  },
  typography: {
    fontFamily: ['"Source Sans Pro"', "sans-serif"].join(',')
   }
});

export default appTheme;
