import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Header from "../views/templateComponents/Header";
import Footer from "../views/templateComponents/Footer";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router";
import { Theme } from '@mui/material'

import "bootstrap/dist/css/bootstrap.min.css";

import "../vendors/animate.css";
import "../vendors/animate-extends.css";
import "../vendors/react-top-loading-bar.css";
import "../vendors/page-transition.css";
import "../vendors/slick/slick.css";
import "../vendors/slick/slick-theme.css";

const baseRouteUrl = "/:locale(uk|en|ru)?/";
const sectionMargin = (margin: any) => margin * 15;
const useStyles = makeStyles((theme: Theme) => ({
  mainWrap: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  spaceBottom: {
    marginBottom: sectionMargin(theme.spacing()),
  },
  spaceTop: {
    paddingTop: sectionMargin(theme.spacing()),
  },
  containerWrap: {
  //  marginTop: theme.spacing(2),
  //  marginLeft: theme.spacing(2),
  //  marginRight: theme.spacing(2),
  },
}));


const Layout = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("common:siteTitle")}</title>
      </Helmet>
      <section id="home" />
      <div className={classes.mainWrap} style={{backgroundColor:"rgb(0, 30, 60)"}}>
        {/* <Header/> */}
        <main className={classes.containerWrap}>
          <Outlet />
        </main>
        <Footer />
      </div>
    </>
  );
};
export default withTranslation()(Layout);
