import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createRootReducer } from './reducers';
import { history } from "./reducers";
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

//sagaMiddleware.run(userSaga);

export default function configureStore(preloadedState?: any) {
    const composeEnhancer: typeof compose =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
      const store = createStore(
        createRootReducer,
        preloadedState,
        composeEnhancer(applyMiddleware(thunk, sagaMiddleware))
      );
  
    return store;
}
