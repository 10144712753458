import { Avatar, Button, Card, Grid, CardActions, CardMedia, IconButton, Paper, Stack, TextField, Typography, CardActionArea } from "@mui/material";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import google from "../../assets/images/google-play-badge.png";
import huawei from "../../assets/images/huawei-badge.png";
import playradiodim from "../../assets/images/play-radiodim-badge.svg";
import appsore from "../../assets/images/appstore-badge.svg";
import microsoft from "../../assets/images/microsoft.png";
import format from "../../assets/images/logo_button.svg";
import stolit from "../../assets/images/stolit-logo-min.svg";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import service1 from "../../assets/images/service-1.png";
import service2 from "../../assets/images/service-2.png";
import service3 from "../../assets/images/service-3.png";
import map from "../../assets/images/map-light.png";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import { useEffect, useState } from "react";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { AlternateEmail, Image, PictureAsPdf } from "@mui/icons-material";
import Chip from '@mui/material/Chip';

//https://jthemes.net/themes/html/bolby/demo/index-3-dark.html
const useStyles = makeStyles((theme: Theme) => ({
    servicesCard: {
        borderRadius: "20px!important",
        // height: 255,
        height: "100%"
    },
    border: {
        borderRadius: "20px!important"
    },
    buttonCV: {
        width: "100%",
        color: "white!important",
        borderRadius: "15px!important",
        '&:hover, &:focus': {
            backgroundColor: "#1a237e21!important"
        }
    },
    buttonContact: {
        width: "100%",
        justifyContent: "left!important",
        // textAlign:"left",
        color: "white!important",
        borderRadius: "15px!important",
        backgroundColor: "#1a237e21!important",
        paddingLeft: "15px!important",
        paddingRight: "15px!important",
        '&:hover, &:focus': {
            backgroundColor: "#1a237e82!important"
        }
    },
    chipWarn: {
        backgroundColor: "#5a3600!important",
        borderColor: "#a66300!important",
        color: "#ffeca1!important",
        fontWeight: "bold!important",
        marginTop: "10px!important",
        marginLeft: 5,
    },
    chipPrim: {
        marginTop: "10px!important",
        backgroundColor: "#003a75!important",
        borderColor: "#005fc1!important",
        color: "#cce5ff!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    chipErr: {
        marginTop: "10px!important",
        backgroundColor: "#570007!important",
        borderColor: "#a3000d!important",
        color: "#ffdbde!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    chipSuccess: {
        marginTop: "10px!important",
        backgroundColor: "#0f5c2e!important",
        borderColor: "#1ba854!important",
        color: "#93e8b5!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
}));

const Home = () => {
    const classes = useStyles();
    const [imagesParall, setImg]: any = useState<number[][] | null>(null);
    const { t } = useTranslation();
    const st = ["space-between", "space-around"];
    useEffect(() => {
        let imagesParallax = [];
        for (let i = 0; i < 5; i++) {
            let ob = [];
            for (let j = 0; j < 3; j++) {
                let randnum = Math.round(Math.random() * 12);
                if (randnum == 0) {
                    randnum = 2;
                }
                ob.push(randnum);
            }
            imagesParallax.push(ob);
        }
        setImg(imagesParallax);
    }, [])
    //const imagesParallax2 = [1, 2, 3, 4, 5, 6]
    return (
        <>
            {/* <Grid container className="justify-content-center">
                <div className="d-flex align-items-center flex-column" style={{ height: "100vh" }}>
                    <MouseParallaxContainer globalFactorX={0.1} globalFactorY={0.1} containerStyle={{
                        width: "100vw",
                        height: "100%"
                    }}>
                        {imagesParall != null ? imagesParall.map((el: any) => {
                            return (
                                <>
                                    <MouseParallaxChild factorX={Math.random()} factorY={Math.random()} style={{ width: "100%",  position:"absolute", display: 'flex', justifyContent: st[Math.round(Math.random())] }}>
                                        {el.map((j: any) => {
                                            return (
                                                <img src={require(`../../assets/images/parallax-images/${j}.png`)} style={{ opacity: Math.random() + 0.2 }} height={Math.random() * 120 + 100} alt="" />
                                            )
                                        })}
                                    </MouseParallaxChild>
                                    {imagesParall.indexOf(el) == 0 ? <MouseParallaxChild factorX={0.0} factorY={0.0} style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                        
                                    </MouseParallaxChild> : null}
                                </>
                            )
                        }) : null}


                    </MouseParallaxContainer>
                    
                </div>
            </Grid> */}
            <div className="p-3">

                <Grid spacing={2} className="mb-5" container justifyContent="center" >
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Paper className={classes.border + " py-4 px-4 "} style={{
                            zIndex: 2,
                            height: "100%",
                            background: "linear-gradient(138deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)"
                        }}>
                            <div className="d-flex justify-content-center flex-column text-center align-items-center">
                                <Avatar
                                    alt={t("common:siteTitle")}
                                    src={require("../../assets/images/danyilAntoniuk.jpg")}
                                    sx={{ width: 130, height: 130 }}
                                />
                                <Typography className="mt-3" variant="h4" gutterBottom component="h1" style={{ fontWeight: 700 }}>{t("home:team:1:title")}</Typography>
                                <Typography className="mt-2" variant="h5" gutterBottom component="h2">{t("home:team:1:subtitle")}</Typography>
                                <Typography variant="h6" gutterBottom component="h3">{t("home:team:1:subtitle2")}</Typography>
                                {/* <Typography className="mt-2" variant="subtitle2" gutterBottom component="p">Досвід роботи 40 років</Typography> */}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={7} >
                        <Paper className={classes.border + " py-4 px-4 "} style={{ zIndex: 2, height: "100%", background: "linear-gradient(236deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)" }}>
                            <div className="d-flex justify-content-center flex-column text-center align-items-center">
                                <Typography variant="h5" component="h4" gutterBottom className="mt-2 mb-1" style={{ fontWeight: 600 }}>
                                    {t("home:skills")}
                                </Typography>
                                <Stack flexWrap={"wrap"} direction="row" justifyContent={"center"} spacing={1}>
                                    <Chip variant="outlined" className={classes.chipPrim} label="C#" size="medium" />
                                    <Chip variant="outlined" className={classes.chipPrim} label="ASP.NET" size="medium" />
                                    <Chip variant="outlined" label="JavaScript" className={classes.chipPrim} size="medium" />
                                    <Chip variant="outlined" label="TypeScript" className={classes.chipPrim} size="medium" />
                                    <Chip variant="outlined" label="React" className={classes.chipPrim} size="medium" />
                                    <Chip variant="outlined" label="Redux" className={classes.chipPrim} size="medium" />
                                    <Chip variant="outlined" label="React Native" className={classes.chipPrim} size="medium" />
                                    <Chip variant="outlined" label="Ajax" className={classes.chipPrim} size="medium" />
                                    <Chip variant="outlined" label="Rest API" className={classes.chipPrim} size="medium" />
                                    <Chip variant="outlined" className={classes.chipPrim} label="Android" size="medium" />
                                    <Chip variant="outlined" className={classes.chipPrim} label="IOS" size="medium" />
                                    <Chip variant="outlined" className={classes.chipPrim} label="Databases" size="medium" />
                                    <Chip variant="outlined" className={classes.chipPrim} label="Ubuntu (Docker)" size="medium" />
                                    <Chip variant="outlined" className={classes.chipPrim} label="AWS" size="medium" />
                                    <Chip variant="outlined" className={classes.chipPrim} label="Azure" size="medium" />
                                </Stack>
                                <Typography variant="h5" component="h4" gutterBottom className="mt-4 mb-1" style={{ fontWeight: 600 }}>
                                    {t("home:aboutMe")}
                                </Typography>
                                <Typography style={{ color: "#ffffffad" }} variant="body1" component="p" gutterBottom className="">
                                    {t("home:summary")}
                                </Typography>
                                {/* <Typography className="mt-2" variant="subtitle2" gutterBottom component="p">Досвід роботи 40 років</Typography> */}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <Paper className={classes.border} style={{ zIndex: 2, height: "100%", background: "linear-gradient(58deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", }}>

                            <Card sx={{ height: "100%", backgroundColor: "#1a237e21!important", borderRadius: "15px" }}>
                                <img
                                    style={{ width: "100%", filter: "brightness(50%)" }}
                                    src={require("../../assets/images/cv-img.png")}
                                />
                                <CardActions >
                                    <Button href="https://danyil.net/cv_us.pdf" size="small" className={classes.buttonCV + " "} style={{ textTransform: "none", fontSize: 14 }}>                            {t("home:downloadCv")}</Button>
                                    {/* <Button size="small">Learn More</Button> */}
                                </CardActions>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3} >
                        <Paper className={classes.border + " py-4 px-4 "} style={{ zIndex: 2, height: "100%", background: "linear-gradient(26deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)" }}>

                            <div className="d-flex justify-content-center flex-column text-center align-items-center">
                                <Typography variant="h5" component="h4" gutterBottom className="mt-2" style={{ fontWeight: 600 }}>
                                    {t("home:contact:title")}
                                </Typography>
                                <Button className={classes.buttonContact + " mt-2"} style={{ textTransform: "none" }} startIcon={<LocationOnIcon />}>
                                    Houston, TX (Ready to relocate)
                                </Button>
                                <Button href="tel:+16466489755" className={classes.buttonContact + " mt-2"} style={{ textTransform: "none" }} startIcon={<LocalPhoneIcon />}>
                                    (646)-648-9755
                                </Button>
                                <Button href="mailto:daniyil.antoniuk@gmail.com" className={classes.buttonContact + " mt-2"} style={{ textTransform: "none" }} startIcon={<AlternateEmail />}>
                                    daniyil.antoniuk@gmail.com
                                </Button>
                                <Button href="https://www.linkedin.com/in/danyil-antoniuk-37a0a91a7/" style={{ textTransform: "none" }} className={classes.buttonContact + " mt-2"} startIcon={<LinkedInIcon />}>
                                    LinkedIn
                                </Button>
                                <Button href="https://github.com/daniylAntoniuk" className={classes.buttonContact + " mt-2"} style={{ textTransform: "none" }} startIcon={<GitHubIcon />}>
                                    GitHub
                                </Button>
                                {/* <Typography className="mt-2" variant="subtitle2" gutterBottom component="p">Досвід роботи 40 років</Typography> */}
                            </div>
                            {/* <Paper className={classes.border + "  "} style={{ zIndex: 2, background: "linear-gradient(49deg, rgb(131 58 180 / 77%) 0%, rgb(253 29 29 / 64%) 50%, rgb(252 176 69 / 66%) 100%)" }}> */}


                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} >
                        <Paper className={classes.servicesCard + " py-4 px-4"} style={{ background: "linear-gradient(299deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)" }}>
                            <div className="d-flex justify-content-center flex-column text-center">
                                <img src={service1} height={80} style={{ objectFit: "contain" }} />
                                <Typography className="mt-3" variant="h5" gutterBottom component="h4" style={{ fontWeight: 600 }}>{t("home:services:webdev")}</Typography>
                                <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:websub")}</Typography>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} >
                        <Paper className={classes.servicesCard + " py-4 px-4"} style={{ background: "linear-gradient(74deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)" }}>
                            <div className="d-flex justify-content-center flex-column text-center">
                                <img src={service2} height={80} style={{ objectFit: "contain" }} />
                                <Typography className="mt-3" variant="h5" gutterBottom component="h4" style={{ fontWeight: 600 }}>{t("home:services:mobiledev")}</Typography>
                                <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:mobilesub")}</Typography>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}  >
                        <Paper className={classes.servicesCard + " py-4 px-4"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                            <div className="d-flex justify-content-center flex-column text-center">
                                <img src={service3} height={80} style={{ objectFit: "contain" }} />
                                <Typography className="mt-3" variant="h5" gutterBottom component="h4" style={{ fontWeight: 600 }}>{t("home:services:designTitle")}</Typography>
                                <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:designsub")}</Typography>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <Typography className="mt-3" variant="h2" gutterBottom component="h2" style={{ fontWeight: "bold" }}>{t("home:projects")}</Typography>
                <Grid spacing={2} container  >
                    <Grid item xs={12} md={6} lg={4}>
                        <CardActionArea href="ukrainian" className={classes.servicesCard}>
                            <Paper className={classes.servicesCard + " py-2 px-2 d-flex flex-row align-items-center"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                                <img src={require("../../assets/images/Icon_white.png")} height={100} width={100} style={{ objectFit: "cover", borderRadius: "15px" }} />
                                <div className="d-flex justify-content-start h-100 flex-column text-left ms-2">
                                    {/* <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:designsub")}</Typography> */}
                                    <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                        {/* <Chip variant="outlined" className={classes.chipPrim} label="2021-2022" size="small" /> */}
                                        <Chip variant="outlined" className={classes.chipSuccess} label={t("home:mobileApps")} size="small" />
                                    </Stack>
                                    <Typography className="mt-2 " variant="h5" gutterBottom component="h4" style={{ marginLeft: 5, fontWeight: 700 }}>{t("home:ukrainian:title")}</Typography>
                                </div>
                            </Paper>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <CardActionArea href="format" className={classes.servicesCard}>
                            <Paper className={classes.servicesCard + " py-2 px-2 d-flex flex-row align-items-center"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                                <img src={format} height={100} width={100} style={{ objectFit: "cover", borderRadius: "15px" }} />
                                <div className="d-flex justify-content-start h-100 flex-column text-left ms-2">
                                    {/* <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:designsub")}</Typography> */}
                                    <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                        {/* <Chip variant="outlined" className={classes.chipPrim} label="2021-2022" size="small" /> */}
                                        <Chip variant="outlined" className={classes.chipSuccess} label={t("home:mobileApps")} size="small" />
                                        <Chip variant="outlined" className={classes.chipErr} label={t("home:adminPanel")} size="small" />
                                    </Stack>
                                    <Typography className="mt-2 " variant="h5" gutterBottom component="h4" style={{ marginLeft: 5, fontWeight: 700 }}>{t("home:format:title")}</Typography>
                                </div>
                            </Paper>
                        </CardActionArea>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <CardActionArea href="radiodim" className={classes.servicesCard}>
                            <Paper className={classes.servicesCard + " py-2 px-2 d-flex flex-row align-items-center"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                                <img src={require("../../assets/images/dim_aut.png")} height={100} width={100} style={{ objectFit: "cover", borderRadius: "15px" }} />
                                <div className="d-flex justify-content-start h-100 flex-column text-left ms-2">
                                    {/* <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:designsub")}</Typography> */}
                                    <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                        {/* <Chip variant="outlined" className={classes.chipPrim} label="2021-2022" size="small" /> */}
                                        <Chip variant="outlined" className={classes.chipSuccess} label={t("home:mobileApps")} size="small" />
                                        <Chip variant="outlined" className={classes.chipWarn} label={t("home:website")} size="small" />
                                        <Chip variant="outlined" className={classes.chipErr} label={t("home:adminPanel")} size="small" />
                                        <Chip variant="outlined" className={classes.chipPrim} label={t("home:windows")} size="small" />
                                    </Stack>
                                    <Typography className="mt-2 " variant="h5" gutterBottom component="h4" style={{ marginLeft: 5, fontWeight: 700 }}>{t("home:radiodim:title")}</Typography>
                                </div>
                            </Paper>
                        </CardActionArea>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <CardActionArea href="stolit" className={classes.servicesCard}>
                            <Paper className={classes.servicesCard + " py-2 px-2 d-flex flex-row align-items-center"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                                <div style={{ display: "flex", width: 100, height: 100, backgroundColor: "white", borderRadius: "15px", justifyContent: "center", alignItems: "center" }}>
                                    <img src={stolit} height={80} width={80} style={{ objectFit: "cover", borderRadius: "15px" }} />
                                </div>
                                <div className="d-flex justify-content-start h-100  flex-column text-left ms-2">
                                    {/* <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:designsub")}</Typography> */}
                                    <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                        <Chip variant="outlined" className={classes.chipWarn} label={t("home:website")} size="small" />
                                        <Chip variant="outlined" className={classes.chipErr} label={t("home:adminPanel")} size="small" />
                                    </Stack>
                                    <Typography className="mt-2" variant="h5" gutterBottom component="h4" style={{ marginLeft: 5, fontWeight: 700 }}>{t("home:stolit:title")}</Typography>
                                </div>
                            </Paper>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <CardActionArea href="siga" className={classes.servicesCard}>
                            <Paper className={classes.servicesCard + " py-2 px-2 d-flex flex-row align-items-center"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                                <img src={require("../../assets/images/tree.jpg")} height={100} width={100} style={{ objectFit: "cover", borderRadius: "15px" }} />
                                <div className="d-flex justify-content-start h-100 flex-column text-left ms-2">
                                    {/* <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:designsub")}</Typography> */}
                                    <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                        {/* <Chip variant="outlined" className={classes.chipPrim} label="2021-2022" size="small" /> */}
                                        <Chip variant="outlined" className={classes.chipSuccess} label={t("home:mobileApps")} size="small" />
                                    </Stack>
                                    <Typography className="mt-2 " variant="h5" gutterBottom component="h4" style={{ marginLeft: 5, fontWeight: 700 }}>{t("home:siga:title")}</Typography>
                                </div>
                            </Paper>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <CardActionArea href="brok" className={classes.servicesCard}>
                            <Paper className={classes.servicesCard + " py-2 px-2 d-flex flex-row align-items-center"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                                <img src={require("../../assets/images/brok.png")} height={100} width={100} style={{ objectFit: "cover", borderRadius: "15px" }} />
                                <div className="d-flex justify-content-start h-100 flex-column text-left ms-2">
                                    {/* <Typography className="mt-2" variant="subtitle1" gutterBottom component="p">{t("home:services:designsub")}</Typography> */}
                                    <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                        {/* <Chip variant="outlined" className={classes.chipPrim} label="2021-2022" size="small" /> */}
                                        <Chip variant="outlined" className={classes.chipWarn} label={t("home:websiteSupport")} size="small" />
                                        <Chip variant="outlined" className={classes.chipPrim} label={t("home:redesign")} size="small" />
                                    </Stack>
                                    <Typography className="mt-2 " variant="h5" gutterBottom component="h4" style={{ marginLeft: 5, fontWeight: 700 }}>{t("home:brok:title")}</Typography>
                                </div>
                            </Paper>
                        </CardActionArea>
                    </Grid>
                </Grid>
                {/* <div className="px-5 mb-4">
                <Typography variant="h4" gutterBottom component="h3">
                    {t("home:radiodim:title")}
                </Typography>
                <Grid spacing={2} container justifyContent="center" className={"mt-5"}>
                    <Grid item xs={12} md={6} >
                        <Paper className={classes.border + " py-4 px-4 d-flex flex-row"} sx={{ overflow: "auto" }}>
                            <img src={require("../../assets/images/image1.jpeg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/image2.jpeg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/image3.jpeg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/image4.jpeg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/image5.jpeg")} style={{ borderRadius: "10px", maxHeight: "350px" }} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} className="d-flex justify-content-center">
                        <Paper className={classes.border + " py-4 px-4"}>
                            <script type="module" src="https://getbadgecdn.azureedge.net/ms-store-badge.bundled.js"></script>
                            <div className="mt-4 mb-2 d-flex justify-content-center">
                                <a href="https://play.radiodim.rv.ua">
                                    <img
                                        style={{ height: "104px" }}
                                        alt="Play Radiodim Logo Badge"
                                        src={playradiodim}
                                    />
                                </a>
                            </div>
                            <div className="mb-3 mb-3 d-flex justify-content-center">
                                <a href="https://apps.microsoft.com/store/detail/radiodim-%E2%80%94-online-radio-player/9N48MN6KVLK6">
                                    <img
                                        style={{ height: "104px" }}
                                        src={microsoft}
                                    />
                                </a>
                            </div>
                            <div className="row mt-3 ">

                                <>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=ua.radiodim"
                                        className="px-2 col-md-4 d-flex justify-content-center"
                                    >
                                        <img className="mb-3" src={google} height={65} alt="logo" />
                                    </a>
                                    <a
                                        href="https://appgallery.huawei.com/app/C104981889"
                                        className="px-2 col-md-4 d-flex justify-content-center"
                                    >
                                        <img className="mb-3" src={huawei} height={65} alt="logo" />
                                    </a>
                                    <a
                                        href="https://apps.apple.com/app/radiodim/id1582682192"
                                        className="px-2 col-md-4 d-flex justify-content-center"
                                    >
                                        <img className="mb-3" src={appsore} height={65} alt="logo" />
                                    </a>
                                </>

                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className="px-5 mt-5" >
                <Typography variant="h4" className="mt-4" gutterBottom component="h3">
                    {t("home:siga:title")}
                </Typography>
                <Grid spacing={2} container justifyContent="center" className={"mt-5"}>
                    <Grid item xs={12} md={6} >
                        <Paper className={classes.border + " py-4 px-4 d-flex flex-row"} sx={{ overflow: "auto" }}>
                            <img src={require("../../assets/images/imageS1.jpg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/imageS2.jpg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/imageS3.jpg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/imageS4.jpg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/imageS5.jpg")} className="me-2" style={{ borderRadius: "10px", maxHeight: "350px" }} />
                            <img src={require("../../assets/images/imageS6.jpg")} style={{ borderRadius: "10px", maxHeight: "350px" }} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} className="d-flex justify-content-center">
                        <Paper className={classes.border + " py-4 px-4"} sx={{ width: "100%" }}>
                            <video
                                style={{ width: "100%", borderRadius: "10px", maxHeight: "350px" }}
                                controls
                                poster={require("../../assets/images/imageS1.jpg")}
                            >
                                <source src={require("../../assets/video/siga.mp4")} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div className="px-5 mt-5" >
                <Typography variant="h4" className="mt-4" gutterBottom component="h3">
                    {t("home:stolit:title")}
                </Typography>
                <Grid spacing={2} container justifyContent="center" className={"mt-5"}>
                    <Grid item xs={12}>
                        <Paper className={classes.border + " py-4 px-4 d-flex flex-column"} sx={{ overflow: "auto" }}>
                            <div className="d-flex flex-row">
                                <img src={require("../../assets/images/Screenshot_1.png")} className="me-2" style={{ borderRadius: "10px", maxHeight: "450px" }} />
                                <img src={require("../../assets/images/Screenshot_2.png")} className="me-2" style={{ borderRadius: "10px", maxHeight: "450px" }} />
                                <img src={require("../../assets/images/Screenshot_3.png")} style={{ borderRadius: "10px", maxHeight: "450px" }} />
                            </div>
                        </Paper>
                        <div className="d-flex justify-content-center mt-3">
                            <a
                                style={{ fontSize: "25px" }}
                                href="https://stolit.in.ua"
                            >https://stolit.in.ua</a>

                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="px-5 pt-5 mt-5">
                <Typography variant="h4" gutterBottom component="h3">
                    {t("home:services:title")}
                </Typography>
                <Grid spacing={2} container justifyContent="center" className={"mt-5"}>

                </Grid>
            </div>
            < div className="px-5 pt-5 mt-5" >
                <Typography variant="h4" gutterBottom component="h3">
                    {t("home:team:title")}
                </Typography>
                <Grid spacing={2} container justifyContent="center" className={"mt-5"}>
                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <Paper className={classes.border + " py-4 px-4"}>
                            <div className="d-flex justify-content-center flex-column text-center align-items-center">
                                <Avatar
                                    alt={t("common:siteTitle")}
                                    sx={{ width: 130, height: 130 }}
                                />
                                <Typography className="mt-3" variant="h5" gutterBottom component="h4" style={{ fontWeight: 700 }}>{t("home:team:1:title")}</Typography>
                                <Typography className="mt-2" variant="h6" gutterBottom component="p">{t("home:team:1:subtitle")}</Typography>
                            </div>
                        </Paper>
                    </Grid>                    
                </Grid>
            </div >
            <div className="px-5 pt-5 mt-5">
                <Typography variant="h4" gutterBottom component="h3">
                    {t("home:contact:title")}
                </Typography>
                <Grid spacing={2} container justifyContent="center" className={"mt-5"}>
                    <Grid item xs={12} sm={8} md={8} lg={8} >
                        <div className="d-flex flex-column align-items-center" style={{ backgroundImage: "url(" + map + ")", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                            <Grid container direction="row" justifyContent="center" className="mt-2">
                                <IconButton href="https://www.linkedin.com/in/danyil-antoniuk-37a0a91a7/" aria-label="delete" size="medium">
                                    <LinkedInIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton href="https://github.com/daniylAntoniuk" aria-label="delete" size="medium">
                                    <GitHubIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                            <div className="d-flex flex-row text-center">
                                <div className="me-4">
                                    <Typography className="mt-3" variant="h5" gutterBottom component="h4">master@danyil.net</Typography>
                                    <Typography className="mt-3" variant="h5" gutterBottom component="h4">danyil.antoniuk@gmail.com</Typography>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div> */}
            </div>

        </>
    )
}

export default Home;