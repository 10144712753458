import './App.css';

//import HomePage from './views/Home';
import DefaultLayout from './layout';

import { Routes, Router } from "react-router-dom";
import { Route } from 'react-router';
import { history } from "./store/reducers";
import { useLayoutEffect, useState } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import i18n from "i18next";
import appTheme from "./theme/appTheme";

import CssBaseline from '@mui/material/CssBaseline';
import Home from './views/Home';
import Radiodim from './views/Radiodim';
import Siga from './views/Siga';
import Ukraininan from './views/Ukrainian';
import Format from './views/Format';
import Stolit from './views/Stolit';
import Brok from './views/Brok';

const baseRouteUrl = "/:locale(uk|en|ru)?/";
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");// + baseRouteUrl;

let themeType = "dark";
// if (typeof Storage !== "undefined") {
//   // eslint-disable-line
//   themeType = localStorage.getItem("appTheme") || "dark";
// }

const App = () => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), [history]);

  const toggleDarkTheme = () => {
    const newPaletteType = theme.palette.type === "light" ? "dark" : "light";
    localStorage.setItem(
      "appTheme",
      theme.palette.type === "light" ? "dark" : "light"
    );
    setTheme({
      ...appTheme("burgundy", newPaletteType),
      direction: theme.direction,
    });
  };

  const [theme, setTheme] = useState<any>({
    ...appTheme("burgundy", themeType),
    direction: i18n.language === "ara" ? "rtl" : "ltr",
  });

  const muiTheme = createTheme(theme);

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Router basename={baseUrl!} location={history.location} navigator={history} navigationType={history.action}>
        <Routes location={history.location} >
          <Route path={"/:locale/"} element={<DefaultLayout />}>
            <Route path={"/:locale/"} element={<Home/>} />
            <Route path={"/:locale/radiodim"} element={<Radiodim/>} />
            <Route path={"/:locale/siga"} element={<Siga/>} />
            <Route path={"/:locale/ukrainian"} element={<Ukraininan/>} />
            <Route path={"/:locale/format"} element={<Format/>} />
            <Route path={"/:locale/stolit"} element={<Stolit/>} />
            <Route path={"/:locale/brok"} element={<Brok/>} />
          </Route>
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;