import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import { history } from "../store/reducers";

import languageEN from "./translations/en/translate";
import languageDE from "./translations/de/translate";
import languageIT from "./translations/it/translate";
import languageUK from "./translations/uk/translate";
import languageRU from "./translations/ru/translate";

i18n.on("languageChanged", function (lng) {
  let finish = false;
  console.log(lng);
  if (!window.location.pathname.includes("/" + lng + "/")) {
    for (var item in i18n.options.otherLanguages) {
      if (
        (window.location.pathname.includes(
          "/" + i18n.options.otherLanguages[item] + "/"
        ) || window.location.pathname.substr(window.location.pathname.length - 3)=="/en") &&
        i18n.options.otherLanguages[item] != lng
      ) {
        const newUrl = window.location.pathname.replace(
          "/" + i18n.options.otherLanguages[item],
          lng
        );
        history.push(newUrl);
        finish = true;
        break;
      }
    }
    if (!finish) {
      history.push("/" + lng + window.location.pathname);
    }
  }
});

i18n
  //.use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: languageEN,
      de: languageDE,
      it: languageIT,
      uk: languageUK,
      //ru: languageRU,
    },
    otherLanguages: ["en", "uk", "de", "it"],
    react: {
      wait: true,
      useSuspense: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
    },
    fallbackLng: ["en"],
    whitelist: ["en", "uk", "it", "de"],
    detection: {
      order: ["path", "localStorage"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
  });

export default i18n;
