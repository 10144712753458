import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import LangIcon from "@material-ui/icons/Language";
import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "react-ionicons/lib/LogoFacebook";
import TwitterIcon from "react-ionicons/lib/LogoTwitter";
import InstagramIcon from "react-ionicons/lib/LogoInstagram";
import LinkedinIcon from "react-ionicons/lib/LogoLinkedin";
import i18n from "i18next";
import "flag-icon-css/css/flag-icon.min.css";

import { withTranslation } from "react-i18next";
//import logo from '../../../public/images/logo.svg';
//import brand from '../../../public/text/brand';
import { useTextAlign } from "../../../theme/common";
import useStyles from "./footer-style";
function Copyright() {
  return (
    <Typography variant="body2" display="block" color="textSecondary">
      &copy;&nbsp; Antoniuk {new Date().getFullYear()}
    </Typography>
  );
}

const footers = [
  {
    title: "Company",
    description: ["Team", "History", "Contact us", "Locations"],
    link: ["#team", "#history", "#contact-us", "#locations"],
  },
  {
    title: "Resources",
    description: [
      "Resource",
      "Resource name",
      "Another resource",
      "Final resource",
    ],
    link: [
      "#resource",
      "#resource-name",
      "#another-resource",
      "#final-resource",
    ],
  },
  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
    link: ["#privacy-policy", "#terms-of-use"],
  },
];

function Footer(props) {
  const [ctn, setCtn] = useState(null);
  // Theme breakpoints
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Translation Function
  const { t } = props;

  const classes = useStyles();
  const align = useTextAlign();
  const [values, setValues] = useState({
    lang: i18n.language,
  });

  useEffect(() => {
    setValues({ lang: i18n.language });
    setCtn(document.getElementById("main-wrap"));
  }, []);
  const pages = ["ukrainian", "format", "radiodim", "stolit", "siga", "brok"];
  function handleChange(event) {
    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
    if (event.target.value === "ar") {
      i18n.changeLanguage("ar");
      //props.toggleDir('rtl');
    } else {
      i18n.changeLanguage(event.target.value);
      //props.toggleDir('ltr');
    }
  }

  return (
    <Container maxWidth="lg" component="footer" className={classes.footer}>
      <Grid container spacing={4}>
        {!isMobile ?
        <Grid item xs={12} md={3}>
          <Copyright />
          {/* {isDesktop && } */}
        </Grid>:<></>}
        <Grid item xs={0} md={6} className={isMobile ? "w-100" : ""}>
          <Grid container spacing={4} justify="space-evenly">
            <Grid item xs={12} md={6} className={classes.siteMapItem + " "}>
              {isDesktop && (
                <div>
                  <Typography
                    variant="h6"
                    className={classes.title}
                    color="textPrimary"
                    gutterBottom
                  >
                    {t("footer:pagesTitle")}
                  </Typography>
                  <ul>
                    <li>
                      <Link
                        className={classes.link}
                        href="/"
                        variant="subtitle1"
                        color="textSecondary"
                      >
                        {t("pages:home")}
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
              {isMobile && (
                <Accordion
                  square
                  style={{
                    background:
                      "linear-gradient(257deg, rgba(8, 0, 138, 0.2) 0%, rgba(9, 9, 121, 0.2) 35%, rgba(0, 212, 255, 0.2) 100%)",
                  }}
                  classes={{
                    root: classes.accordionRoot,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={classes.accordionIcon} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                      content: classes.accordionContent,
                    }}
                  >
                    <strong>{t("footer:pagesTitle")}</strong>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      <li>
                        <Link
                          href="/"
                          variant="subtitle1"
                          color="textSecondary"
                        >
                          {t("pages:home")}
                        </Link>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
            <Grid item xs={12} md={6} className={classes.siteMapItem}>
              {isDesktop && (
                <div>
                  <Typography
                    variant="h6"
                    className={classes.title}
                    color="textPrimary"
                    gutterBottom
                  >
                    {t("home:projects")}
                  </Typography>
                  <ul>
                    {pages.map((el) => (
                      <li key={el}>
                        <Link
                          className={classes.link}
                          href={"/" + el}
                          variant="subtitle1"
                          color="textSecondary"
                        >
                          {t("home:" + el + ":title")}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {isMobile && (
                <Accordion
                  square
                  style={{
                    background:
                      "linear-gradient(257deg, rgba(8, 0, 138, 0.2) 0%, rgba(9, 9, 121, 0.2) 35%, rgba(0, 212, 255, 0.2) 100%)",
                  }}
                  classes={{
                    root: classes.accordionRoot,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon className={classes.accordionIcon} />
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{
                      content: classes.accordionContent,
                    }}
                  >
                    <strong>{t("home:projects")}</strong>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {pages.map((el) => (
                        <li key={el}>
                          <Link
                            href={"/"+el}
                            variant="subtitle1"
                            color="textSecondary"
                          >
                            {t("home:"+el+":title")}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <div>
            {/* className={classes.socmed}> */}
            {/* <IconButton
              aria-label="Delete"
              className={classes.margin}
              size="small"
            >
              <FacebookIcon fontSize="64" />
            </IconButton>
            <IconButton
              aria-label="Delete"
              className={classes.margin}
              size="small"
            >
              <TwitterIcon fontSize="64px" />
            </IconButton>
            <IconButton
              aria-label="Delete"
              className={classes.margin}
              size="small"
            >
              <InstagramIcon fontSize="64px" />
            </IconButton> */}
            {/* <IconButton
              aria-label="Delete"             
              className={classes.margin}
              onClick={()=>{window.location.href = 'https://www.linkedin.com/in/danyil-antoniuk-37a0a91a7';}}
              size="small"
            >
              <LinkedinIcon fontSize="64px" style={{color:"white"}} />
            </IconButton> */}
          </div>
          <Select
            value={i18n.language}
            onChange={handleChange}
            MenuProps={{
              container: ctn,
            }}
            style={{ borderRadius: "15px" }}
            className={classes.selectLang + " w-100"}
            input={
              <OutlinedInput
                //labelWidth={200}
                name="lang"
                id="outlined-lang-simple"
              />
            }
          >
            {i18n.options.otherLanguages &&
              i18n.options.otherLanguages.map((val) => (
                <MenuItem key={val} value={val}>
                  <i
                    className={
                      " flag-icon flag-icon-" + t("common:countryCode:" + val)
                    }
                  />
                  <span className="ms-2">{t("common:" + val)}</span>
                </MenuItem>
              ))}
          </Select>
        </Grid>
      </Grid>
      {isMobile && (
        <div className={align.textCenter}>
          <Box p={4}>
            <Copyright />
          </Box>
        </div>
      )}
    </Container>
  );
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Footer);
