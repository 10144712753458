import { Typography, Stack, Grid, IconButton, Paper, Button } from "@mui/material";
import Chip from '@mui/material/Chip';
import { useTranslation } from "react-i18next";
import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import google from "../../assets/images/google-play-badge.png";
import huawei from "../../assets/images/huawei-badge.png";
import playradiodim from "../../assets/images/play-radiodim-badge.svg";
import appsore from "../../assets/images/appstore-badge.svg";
import microsoft from "../../assets/images/microsoft.png";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Testimonials from "../templateComponents/Testimonials";
//@ts-ignore
import Slider from 'react-slick';
import { useRef, useState, useEffect, SyntheticEvent } from "react";
import { PlayArrow } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const useStyles = makeStyles((theme: Theme) => ({
    servicesCard: {
        borderRadius: "20px!important",
        // height: 255,
        // height: "100%"
    },
    border: {
        borderRadius: "20px!important"
    },
    buttonCV: {
        width: "100%",
        color: "white!important",
        borderRadius: "15px!important",
        '&:hover, &:focus': {
            backgroundColor: "#1a237e21!important"
        }
    },
    buttonContact: {
        //width: "100%",
        justifyContent: "right!important",
        // textAlign:"left",
        color: "white!important",
        borderRadius: "15px!important",
        backgroundColor: "#021b4970!important",
        paddingLeft: "15px!important",
        paddingRight: "15px!important",
        '&:hover, &:focus': {
            backgroundColor: "#1a237e82!important"
        }
    },
    chipWarn: {
        backgroundColor: "#5a3600!important",
        borderColor: "#a66300!important",
        color: "#ffeca1!important",
        fontWeight: "bold!important",
        marginTop: "10px!important",
        marginLeft: 5,
    },
    chipPrim: {
        marginTop: "10px!important",
        backgroundColor: "#003a75!important",
        borderColor: "#005fc1!important",
        color: "#cce5ff!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    chipErr: {
        marginTop: "10px!important",
        backgroundColor: "#570007!important",
        borderColor: "#a3000d!important",
        color: "#ffdbde!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    chipSuccess: {
        marginTop: "10px!important",
        backgroundColor: "#0f5c2e!important",
        borderColor: "#1ba854!important",
        color: "#93e8b5!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    imageContainer: {
        height: "100%", background: "linear-gradient(to right, rgb(0 30 60) 0px, rgba(0, 0, 0, 0) 56%) 0% 0% / cover, linear-gradient(to top, rgb(0 30 60) 0px, rgba(0, 0, 0, 0) 56%), url(https://radiodim.rv.ua/img/preview.png), no-repeat", backgroundSize: "cover"
    },
    item: {
        borderRadius: "15px",
        width: "100%",
    },
    tab: {
        borderRadius: "20px!important",
        textTransform: 'none',
        //fontWeight: theme.typography.fontWeightRegular,
        //fontSize: theme.typography.pxToRem(15),
        //marginRight: theme.spacing(1),
        color: 'rgba(255, 255, 255, 0.7)!important',
        '&.Mui-selected': {
            color: '#cce2f2!important',
            backgroundColor: '#003a75!important',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)!important',
        },
    },
    tabList: {
        textTransform: 'none',
        minWidth: 0,
        color: 'rgba(0, 0, 0, 0.85)!important',

        '&:hover': {
            color: '#40a9ff!important',
            opacity: 1,
        },
        '&.Mui-selected': {
            color: '#1890ff!important',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff!important',
        },
        '& .MuiTabs-indicator': {
            display: 'flex!important',
            height: "100%",
            border: "1px #005fc1 solid",
            //maxWidth: "40px!important",
            justifyContent: 'center!important',
            backgroundColor: 'transparent!important',
            borderRadius: "20px!important"
        },
    }
}));

const Radiodim = () => {
    const slider = useRef(null);
    const slider2 = useRef(null);
    const slider3 = useRef(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles();
    const { t } = useTranslation();
    // const videoRef = useRef(null);
    // useEffect(() => {
    //     //@ts-ignore
    //     videoRef.current.volume = 0.3;
    // }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }, {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }, {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    };
    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },]
    };
    const [value, setValue] = useState('1');

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={0} display={{ lg: "none" }} className={classes.imageContainer + " pb-3 pe-3"} >
                    <img src="https://radiodim.rv.ua/img/preview.png" style={{ height: "100%", width: "80%", visibility: "hidden" }} />
                    <div className="d-flex w-100 justify-content-end mr-3">
                        <Button onClick={handleOpen} className={classes.buttonContact + " "} size="large" style={{ textTransform: "none" }} startIcon={<PlayArrow />}>
                            {t("home:video")}
                        </Button>
                    </div>
                </Grid>
                <Grid item lg={4} className="py-3 px-3">
                    <Paper className={classes.servicesCard + " px-3 py-3 h-100 d-flex flex-column justify-content-between"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                        <div>
                            <Typography variant="h2" className="" component="h1">{t("home:radiodim:title")}</Typography>
                            <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                {/* <Chip variant="outlined" className={classes.chipPrim} label="2021-2022" size="small" /> */}
                                <Chip variant="outlined" className={classes.chipSuccess} label={t("home:mobileApps")} size="small" />
                                <Chip variant="outlined" className={classes.chipWarn} label={t("home:website")} size="small" />
                                <Chip variant="outlined" className={classes.chipErr} label={t("home:adminPanel")} size="small" />
                                <Chip variant="outlined" className={classes.chipPrim} label={t("home:windows")} size="small" />
                            </Stack>
                            <Typography variant="body1" component="p" className="mt-4" >{t("home:radiodim:description")}</Typography>
                        </div>
                        <Grid container spacing={1} justifyContent={"center"} className="mt-2" display={{ xs: "none", md: "flex" }}>
                            <Grid item md={6}>
                                <script type="module" src="https://getbadgecdn.azureedge.net/ms-store-badge.bundled.js"></script>
                                <div className="mb-2 d-flex justify-content-center">
                                    <a href="https://play.radiodim.rv.ua">
                                        <img
                                            style={{ height: "64px" }}
                                            alt="Play Radiodim Logo Badge"
                                            src={playradiodim}
                                        />
                                    </a>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div className="mb-3 mb-3 d-flex justify-content-center">
                                    <a href="https://apps.microsoft.com/store/detail/radiodim-%E2%80%94-online-radio-player/9N48MN6KVLK6">
                                        <img
                                            style={{ height: "64px" }}
                                            src={microsoft}
                                        />
                                    </a>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div className="mb-3 mb-3 d-flex justify-content-center">
                                    <a href="https://play.google.com/store/apps/details?id=ua.radiodim">
                                        <img
                                            style={{ height: "64px" }}
                                            src={google}
                                        />
                                    </a>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div className="mb-3 mb-3 d-flex justify-content-center">
                                    <a href="https://apps.apple.com/app/radiodim/id1582682192">
                                        <img
                                            style={{ height: "64px" }}
                                            src={appsore}
                                        />
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={0} sm={0} md={0} lg={8} display={{ xs: "none", lg: "block" }} className={classes.imageContainer + " pb-3 pe-3"} >
                    <img src="https://radiodim.rv.ua/img/preview.png" style={{ height: "100%", width: "100%", visibility: "hidden" }} />
                    <div className="d-flex w-100 justify-content-end mr-3">
                        <Button onClick={handleOpen} className={classes.buttonContact + " "} size="large" style={{ textTransform: "none" }} startIcon={<PlayArrow />}>
                            {t("home:video")}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Paper className={classes.servicesCard + " mt-3 pb-4 mx-3"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                <TabContext value={value}>
                    <TabList className={classes.tabList} onChange={handleChange} aria-label="lab API tabs example">
                        <Tab className={classes.tab} label={t("home:mobileApps")} value="1" />
                        <Tab className={classes.tab} label={t("home:website")} value="2" />
                        <Tab className={classes.tab} label={t("home:windows")} value="3" />
                    </TabList>
                    <TabPanel value="1">
                        <Grid container alignItems={"center"} justifyContent={"center"} className=" ">

                            <Grid item sm={1} xs={2}>
                                <IconButton onClick={() => {
                                    //@ts-ignore
                                    slider?.current?.slickPrev()
                                }}> <KeyboardArrowLeft style={{ color: 'white' }} fontSize="large" /></IconButton>
                            </Grid>
                            <Grid item sm={10} xs={8}>
                                <Slider ref={slider} {...settings}>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/image1.jpeg")} className={classes.item} />
                                    </div>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/image2.jpeg")} className={classes.item} />
                                    </div>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/image3.jpeg")} className={classes.item} />
                                    </div>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/image4.jpeg")} className={classes.item} />
                                    </div>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/image5.jpeg")} className={classes.item} />
                                    </div>
                                </Slider >
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item sm={1} xs={2}>
                                <IconButton onClick={() => {
                                    //@ts-ignore
                                    slider?.current?.slickNext()
                                }}> <KeyboardArrowRight style={{ color: 'white' }} fontSize="large" /></IconButton>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="2">
                        <Grid container alignItems={"center"} justifyContent={"center"} className=" ">

                            <Grid item sm={1} xs={2}>
                                <IconButton onClick={() => {
                                    //@ts-ignore
                                    slider2?.current?.slickPrev()
                                }}> <KeyboardArrowLeft style={{ color: 'white' }} fontSize="large" /></IconButton>
                            </Grid>
                            <Grid item sm={10} xs={8}>
                                <Slider ref={slider2} {...settings2}>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/rdweb.png")} className={classes.item} />
                                    </div>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/rdweb2.png")} className={classes.item} />
                                    </div>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/rdweb3.png")} className={classes.item} />
                                    </div>
                                </Slider >
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item sm={1} xs={2}>
                                <IconButton onClick={() => {
                                    //@ts-ignore
                                    slider2?.current?.slickNext()
                                }}> <KeyboardArrowRight style={{ color: 'white' }} fontSize="large" /></IconButton>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="3">
                        <Grid container alignItems={"center"} justifyContent={"center"} className=" ">

                            <Grid item sm={1} xs={2}>
                                <IconButton onClick={() => {
                                    //@ts-ignore
                                    slider3?.current?.slickPrev()
                                }}> <KeyboardArrowLeft style={{ color: 'white' }} fontSize="large" /></IconButton>
                            </Grid>
                            <Grid item sm={10} xs={8}>
                                <Slider ref={slider3} {...settings2}>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/rdWin.png")} className={classes.item} />
                                    </div>
                                    <div className="px-2 d-flex justify-content-center">
                                        <img src={require("../../assets/images/rdWin2.png")} className={classes.item} />
                                    </div>
                                </Slider >
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "flex-end" }} item sm={1} xs={2}>
                                <IconButton onClick={() => {
                                    //@ts-ignore
                                    slider3?.current?.slickNext()
                                }}> <KeyboardArrowRight style={{ color: 'white' }} fontSize="large" /></IconButton>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Paper>

            <Grid display={{ md: "none" }} container spacing={1} justifyContent={"center"} className="mt-4 ">
                <Grid item md={6}>
                    <script type="module" src="https://getbadgecdn.azureedge.net/ms-store-badge.bundled.js"></script>
                    <div className="mb-2 d-flex justify-content-center">
                        <a href="https://play.radiodim.rv.ua">
                            <img
                                style={{ height: "64px" }}
                                alt="Play Radiodim Logo Badge"
                                src={playradiodim}
                            />
                        </a>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div className="mb-3 mb-3 d-flex justify-content-center">
                        <a href="https://apps.microsoft.com/store/detail/radiodim-%E2%80%94-online-radio-player/9N48MN6KVLK6">
                            <img
                                style={{ height: "64px" }}
                                src={microsoft}
                            />
                        </a>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div className="mb-3 mb-3 d-flex justify-content-center">
                        <a href="https://play.google.com/store/apps/details?id=ua.radiodim">
                            <img
                                style={{ height: "64px" }}
                                src={google}
                            />
                        </a>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div className="mb-3 mb-3 d-flex justify-content-center">
                        <a href="https://apps.apple.com/app/radiodim/id1582682192">
                            <img
                                style={{ height: "64px" }}
                                src={appsore}
                            />
                        </a>
                    </div>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="video-modal-title"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}

            >
                {/* <Paper className={classes.servicesCard + " px-3 py-3"} style={{ background: "linear-gradient(257deg, #02184b 0%, #02184b 100%, #004162 100%)", boxShadow: "0px 5px 20px 0px #004061;" }}> */}
                {/* <div className="d-flex justify-content-center"> */}
                <video
                    // ref={videoRef}
                    style={{ width: "80%", borderRadius: "20px" }}
                    controls

                    poster="https://radiodim.rv.ua/img/preview.png"
                >
                    <source src="https://radiodim.rv.ua/video/main-vid.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {/* </div> */}

                {/* </Paper> */}
            </Modal>
        </>
    )
}
export default Radiodim;