import { Typography, Stack, Grid, IconButton, Paper, Button } from "@mui/material";
import Chip from '@mui/material/Chip';
import { useTranslation } from "react-i18next";
import { Theme } from '@mui/material';
import { makeStyles } from "@mui/styles";
import google from "../../assets/images/google-play-badge.png";
import appsore from "../../assets/images/appstore-badge.svg";
import microsoft from "../../assets/images/microsoft.png";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Testimonials from "../templateComponents/Testimonials";
//@ts-ignore
import Slider from 'react-slick';
import { useRef, useState,useEffect } from "react";
import { PlayArrow } from "@mui/icons-material";
import Modal from '@mui/material/Modal';

const useStyles = makeStyles((theme: Theme) => ({
    servicesCard: {
        borderRadius: "20px!important",
        // height: 255,
        // height: "100%"
    },
    border: {
        borderRadius: "20px!important"
    },
    buttonCV: {
        width: "100%",
        color: "white!important",
        borderRadius: "15px!important",
        '&:hover, &:focus': {
            backgroundColor: "#1a237e21!important"
        }
    },
    buttonContact: {
        //width: "100%",
        justifyContent: "right!important",
        // textAlign:"left",
        color: "white!important",
        borderRadius: "15px!important",
        backgroundColor: "#021b4970!important",
        paddingLeft: "15px!important",
        paddingRight: "15px!important",
        '&:hover, &:focus': {
            backgroundColor: "#1a237e82!important"
        }
    },
    chipWarn: {
        backgroundColor: "#5a3600!important",
        borderColor: "#a66300!important",
        color: "#ffeca1!important",
        fontWeight: "bold!important",
        marginTop: "10px!important",
        marginLeft: 5,
    },
    chipPrim: {
        marginTop: "10px!important",
        backgroundColor: "#003a75!important",
        borderColor: "#005fc1!important",
        color: "#cce5ff!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    chipErr: {
        marginTop: "10px!important",
        backgroundColor: "#570007!important",
        borderColor: "#a3000d!important",
        color: "#ffdbde!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    chipSuccess: {
        marginTop: "10px!important",
        backgroundColor: "#0f5c2e!important",
        borderColor: "#1ba854!important",
        color: "#93e8b5!important",
        fontWeight: "bold!important",
        marginLeft: 5,
    },
    imageContainer: {
        height: "100%", background: "linear-gradient(to right, rgb(0 30 60) 0px, rgba(0, 0, 0, 0) 56%) 0% 0% / cover, linear-gradient(to top, rgb(0 30 60) 0px, rgba(0, 0, 0, 0) 56%), url("+require("../../assets/images/ukposter.png")+"), no-repeat", backgroundSize: "cover"
    },
    item: {
        borderRadius: "15px",
        width: "100%",
    },
}));

const Ukraininan = () => {
    const slider = useRef(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const classes = useStyles();
    const { t } = useTranslation();
    // const videoRef = useRef(null);
    // useEffect(() => {
    //     //@ts-ignore
    //     videoRef.current.volume = 0.3;
    // }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }, {
            breakpoint: 950,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }, {
            breakpoint: 770,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={0} display={{ lg: "none" }} className={classes.imageContainer + " pb-3 pe-3"} >
                    <img src={require("../../assets/images/ukposter.png")} style={{ height: "100%", width: "80%", visibility: "hidden" }} />
                    <div className="d-flex w-100 justify-content-end mr-3">
                        {/* <Button onClick={handleOpen} className={classes.buttonContact + " "} size="large" style={{ textTransform: "none" }} startIcon={<PlayArrow />}>
                            {t("home:video")}
                        </Button> */}
                    </div>
                </Grid>
                <Grid item  lg={4} className="py-3 px-3">
                    <Paper className={classes.servicesCard + " px-3 py-3 h-100 d-flex flex-column justify-content-between"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                        <div>
                            <Typography variant="h2" className="" component="h1">{t("home:ukrainian:title")}</Typography>
                            <Stack flexWrap={"wrap"} direction="row" spacing={0}>
                                {/* <Chip variant="outlined" className={classes.chipPrim} label="2021-2022" size="small" /> */}
                                <Chip variant="outlined" className={classes.chipSuccess} label={t("home:mobileApps")} size="small" />                                
                            </Stack>
                            <Typography variant="body1" component="p" className="mt-4" >{t("home:ukrainian:description")}</Typography>
                        </div>
                        <Grid container spacing={1} justifyContent={"center"} className="mt-2" display={{ xs: "none", md: "flex" }}>                           
                            <Grid item md={6}>
                                <div className="mb-3 mb-3 d-flex justify-content-center">
                                    <a href="https://play.google.com/store/apps/details?id=com.ukrainianus">
                                        <img
                                            style={{ height: "64px" }}
                                            src={google}
                                        />
                                    </a>
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div className="mb-3 mb-3 d-flex justify-content-center">
                                    <a href="https://apps.apple.com/us/app/ukrainian-us-%D0%B4%D0%BE%D1%88%D0%BA%D0%B0-%D0%BE%D0%B3%D0%BE%D0%BB%D0%BE%D1%88%D0%B5%D0%BD%D1%8C/id6446788803">
                                        <img
                                            style={{ height: "64px" }}
                                            src={appsore}
                                        />
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={0} sm={0} md={0} lg={8} display={{ xs: "none", lg: "block" }} className={classes.imageContainer + " pb-3 pe-3"} >
                    <img src={require("../../assets/images/ukposter.png")} style={{ height: "100%", width: "100%", visibility: "hidden" }} />
                    <div className="d-flex w-100 justify-content-end mr-3">
                        {/* <Button onClick={handleOpen} className={classes.buttonContact + " "} size="large" style={{ textTransform: "none" }} startIcon={<PlayArrow />}>
                            {t("home:video")}
                        </Button> */}
                    </div>
                </Grid>
            </Grid>
            <Paper className={classes.servicesCard + " mt-3 py-4 mx-3"} style={{ background: "linear-gradient(257deg, rgba(8, 0, 138, 0.20) 0%, rgb(9, 9, 121, 0.20) 35%, rgba(0, 212, 255, 0.20) 100%)", boxShadow: "0px 5px 20px 0px rgb(249 123 139 / 50%);" }}>
                <Grid container alignItems={"center"} justifyContent={"center"} className=" ">

                    <Grid item sm={1} xs={2}>
                        <IconButton onClick={() => {
                            //@ts-ignore
                            slider?.current?.slickPrev()
                        }}> <KeyboardArrowLeft style={{ color: 'white' }} fontSize="large" /></IconButton>
                    </Grid>
                    <Grid item  sm={10} xs={8}>
                        <Slider ref={slider} {...settings}>
                            <div className="px-2 d-flex justify-content-center">
                                <img src={require("../../assets/images/uk1.jpeg")} className={classes.item} />
                            </div>
                            <div className="px-2 d-flex justify-content-center">
                                <img src={require("../../assets/images/uk2.jpeg")} className={classes.item} />
                            </div>
                            <div className="px-2 d-flex justify-content-center">
                                <img src={require("../../assets/images/uk3.jpeg")} className={classes.item} />
                            </div>
                            <div className="px-2 d-flex justify-content-center">
                                <img src={require("../../assets/images/uk4.jpeg")} className={classes.item} />
                            </div>
                            <div className="px-2 d-flex justify-content-center">
                                <img src={require("../../assets/images/uk5.png")} className={classes.item} />
                            </div>
                        </Slider >
                    </Grid>
                    <Grid sx={{display:"flex", justifyContent:"flex-end"}} item sm={1} xs={2}>
                        <IconButton onClick={() => {
                            //@ts-ignore
                            slider?.current?.slickNext()
                        }}> <KeyboardArrowRight style={{ color: 'white' }} fontSize="large" /></IconButton>
                    </Grid>
                </Grid>
            </Paper>

            <Grid display={{ md: "none" }} container spacing={1} justifyContent={"center"} className="mt-4 ">
                
                <Grid item md={6}>
                    <div className="mb-3 mb-3 d-flex justify-content-center">
                        <a href="https://play.google.com/store/apps/details?id=com.ukrainianus">
                            <img
                                style={{ height: "64px" }}
                                src={google}
                            />
                        </a>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div className="mb-3 mb-3 d-flex justify-content-center">
                        <a href="https://apps.apple.com/us/app/ukrainian-us-%D0%B4%D0%BE%D1%88%D0%BA%D0%B0-%D0%BE%D0%B3%D0%BE%D0%BB%D0%BE%D1%88%D0%B5%D0%BD%D1%8C/id6446788803">
                            <img
                                style={{ height: "64px" }}
                                src={appsore}
                            />
                        </a>
                    </div>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="video-modal-title"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}

            >
                {/* <Paper className={classes.servicesCard + " px-3 py-3"} style={{ background: "linear-gradient(257deg, #02184b 0%, #02184b 100%, #004162 100%)", boxShadow: "0px 5px 20px 0px #004061;" }}> */}
                {/* <div className="d-flex justify-content-center"> */}
                    <video
                       // ref={videoRef}
                        style={{  width: "80%", borderRadius:"20px" }}
                        controls
                        
                        poster="https://ukrainian.rv.ua/img/preview.png"
                    >
                        <source src="https://ukrainian.rv.ua/video/main-vid.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                {/* </div> */}
                
                {/* </Paper> */}
            </Modal>
        </>
    )
}
export default Ukraininan;