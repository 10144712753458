import common from "./common.json";
import navbar from "./navbar.json";
import portfolio from "./portfolio.json";
import footer from "./footer.json";
import pages from "./pages.json";
import home from "./home.json";

export default {
    common,
    navbar,
    footer,
    pages,
    home,
    portfolio
}