import { combineReducers } from "redux";
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const createRootReducer = combineReducers({
  //user: userReducer,
});

export type RootState = ReturnType<typeof createRootReducer>;
